import React from "react";
import { css } from "@emotion/react";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { RectSkeleton } from "main/javascripts/components/skeleton/RectSkeleton";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";

export const SuggestionItemSkeleton = (): React.ReactElement => {
  return (
    <div css={suggestStyle}>
      <div css={iconStyle}>
        <RectSkeleton />
      </div>
      <div css={suggestionValueBlockStyle}>
        <div css={suggestionSkeletonStyle}>
          <RectSkeleton />
        </div>
      </div>
    </div>
  );
};

const suggestStyle = css`
  display: flex;
  margin: 0;
  padding: ${space.atom1_5x} ${space.atom2x} ${space.atom1_5x};
`;
const iconStyle = css`
  width: ${fontSize.headline};
  height: ${fontSize.headline};
  margin: 0 1.25rem 0 ${space.atom};
  border-radius: ${borderRadius.normal};
  overflow: hidden;
`;
const suggestionValueBlockStyle = css`
  display: flex;
  align-items: center;
  width: 50%;
  height: ${lineHeight.body2};
`;
const suggestionSkeletonStyle = css`
  width: 100%;
  height: ${fontSize.body};
  border-radius: ${borderRadius.normal};
  overflow: hidden;
`;
