import React from "react";
import { css, keyframes } from "@emotion/react";

export const RectSkeleton: React.FC = (): React.ReactElement => {
  return <div css={blockStyle} />;
};

const shineKeyframes = keyframes`
  0% {
    background-position: 0;
  }
  100% {
    background-position: 800px;
  }
`;

// 表示サイズは上位の要素で調整する
const blockStyle = css`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    #e1e1e1 0%,
    #e1e1e1 30%,
    #f5f5f5 50%,
    #e1e1e1 70%,
    #e1e1e1 100%
  );
  animation-name: ${shineKeyframes};
  animation-duration: 2s;
  animation-delay: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  background-size: 800px;
`;
