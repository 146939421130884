import * as React from "react";
import { css } from "@emotion/react";
import { FieldError } from "react-hook-form";
import { Label, ILabelProps } from "main/javascripts/components/form/Label";
import { InputError } from "main/javascripts/components/form/InputError";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";

export interface IProps {
  name: string;
  namespace: string;
  label: ILabelProps;
  date: any;
  onClick: any;
  isActive?: boolean;
  error?: FieldError;
  inputBlockRef?: any;
  borderColorStyleKey?: keyof IBorderColorStyle;
}

export const CalendarDateBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    name,
    namespace,
    label,
    date,
    isActive,
    error,
    borderColorStyleKey = "none",
  } = props;
  const errorStyles: any = error ? errorStyle : "";
  const placeholderStyles: any = date ? "" : placeholderStyle;

  const dateStyles = isActive ? dateActiveStyle : dateStyle;
  // TODO: InputErrorStyle
  return (
    <div
      css={blockStyle}
      className="calendar-date-block"
      ref={props.inputBlockRef}
      onClick={props.onClick}
    >
      <Label {...label} />
      <div
        css={[
          dateStyles,
          dateBorderColorStyle[borderColorStyleKey],
          errorStyles,
          placeholderStyles,
        ]}
      >
        {date || "年 / 月 / 日"}
      </div>
      {error && (
        <InputError
          namespace={namespace}
          errorMessage={error.message}
          label={name}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  width: 100%;
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2)
    ${space.atom} ${space.atom};
  box-sizing: border-box;
`;
// const dateErrorStyle = css`
//   padding: ${space.atom0_5x} 0 0;
// `;
const dateStyle = css`
  font-size: ${fontSize.form};
  font-weight: ${fontWeight.regular};
  color: ${textColor.primaryDarkColor};
  caret-color: ${accentColor.primaryColor};
  padding: 0 ${space.atom1_5x} 0;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid;
  border-radius: ${borderRadius.normal};
  background-color: #fff;
  appearance: none;
  cursor: pointer;
`;
const dateActiveStyle = css`
  ${dateStyle};
  color: ${accentColor.primaryColor};
  border-bottom-color: ${accentColor.primaryColor};
`;
const errorStyle = css`
  border-bottom-color: ${accentColor.alertColor};
`;
const placeholderStyle = css`
  color: ${textColor.disabledColor};
`;

const dateBorderColorStyle: IBorderColorStyle = {
  none: css`
    border-color: white;
  `,
  primary: css`
    border-color: ${borderColor.primaryDarkColor};
  `,
  secondary: css`
    border-color: ${borderColor.secondaryLightColor};
  `,
};
